import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';

import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import "core-js/modules/es.array.concat.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c(VSheet, {
    staticClass: "overflow-hidden",
    staticStyle: {
      "max-width": "1200px"
    },
    attrs: {
      "rounded": "",
      "outlined": ""
    }
  }, [_c(VRow, {
    staticClass: "color-background text-subtitle-2 secondary-text--text px-4 py-2",
    attrs: {
      "no-gutters": ""
    }
  }, [_c(VCol, {
    attrs: {
      "cols": "5",
      "md": "4"
    }
  }, [_vm._v(_vm._s(_setup.from))]), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "2",
      "md": "4"
    }
  }), _vm._v(" "), _c(VCol, {
    attrs: {
      "cols": "5",
      "md": "4"
    }
  }, [_vm._v(_vm._s(_setup.to))])], 1), _vm._v(" "), _c(VDivider), _vm._v(" "), _vm._l(_setup.properties, function (prop) {
    var _setup$selectedMap$pr, _setup$selectedMap$pr2;
    return _c(VRow, {
      key: prop.field,
      staticClass: "mx-4 my-2",
      attrs: {
        "no-gutters": ""
      }
    }, [_c(VCol, {
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('Input', {
      attrs: {
        "value": _setup.selectedMap[prop.field],
        "type": "autocomplete",
        "items": (_setup$selectedMap$pr = _setup.selectedMap[prop.field]) !== null && _setup$selectedMap$pr !== void 0 && _setup$selectedMap$pr.disabled ? [_setup.selectedMap[prop.field]].concat(_toConsumableArray(_setup.selectableFields)) : _setup.selectableFields,
        "item-text": "label",
        "item-value": "name",
        "item-disabled": "disabled",
        "return-object": "",
        "error-messages": (_setup$selectedMap$pr2 = _setup.selectedMap[prop.field]) !== null && _setup$selectedMap$pr2 !== void 0 && _setup$selectedMap$pr2.disabled ? _setup.$t('error.deletedSetCrmProps', [_setup.crmName]) : null,
        "placeholder": _setup.$t('notSet'),
        "clearable": !prop.required,
        "required": prop.required
      },
      on: {
        "change": function change($event) {
          _setup.selectedMap[prop.field] = $event !== null && $event !== void 0 ? $event : undefined;
        }
      }
    })], 1), _vm._v(" "), _c(VCol, {
      staticClass: "d-flex align-center",
      attrs: {
        "cols": "2",
        "md": "4"
      }
    }, [_c('span', {
      staticClass: "mx-4 mx-md-8 thinright"
    })]), _vm._v(" "), _c(VCol, {
      staticClass: "text-body-2 d-flex align-center",
      attrs: {
        "cols": "4"
      }
    }, [_vm._v("\n      " + _vm._s(prop.label) + "\n      "), _vm._v(" "), prop.required ? _c('RequiredIcon', {
      staticClass: "ml-1"
    }) : _vm._e(), _vm._v(" "), prop.insertion ? _c('FloatingTooltip', {
      attrs: {
        "top": "",
        "hoverable": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref) {
          var on = _ref.on,
            attrs = _ref.attrs;
          return [_c(VIcon, _vm._g(_vm._b({
            staticClass: "ml-1",
            attrs: {
              "size": "18",
              "color": "secondary-text"
            }
          }, 'v-icon', attrs, false), on), [_vm._v("\n            mdi-code-braces-box\n          ")])];
        }
      }], null, true)
    }, [_vm._v(" "), _c('span', {
      staticClass: "text-body-2"
    }, [_vm._v("\n          " + _vm._s(_setup.$t('canUseAsInsertionPlaceholder')) + "\n          "), _c('IconButton', {
      attrs: {
        "icon": "mdi-help-circle"
      },
      on: {
        "click": _vm.$openAboutPlaceholder
      }
    })], 1)]) : _vm._e()], 1)], 1);
  })], 2);
};
var staticRenderFns = [];
export { render, staticRenderFns };